
import { Box, Typography, Modal } from '@mui/material';
import { PrimaryButton, CloseButton } from "../Buttons/Buttons";


function SideModal({ mode, setMode }) {
    return (
        <Modal
            open={true}
            onClose={() => { setMode(null) }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            <Box>
                {
                    mode === "email" &&
                    <Box
                        sx={{
                            minWidth: "200px",
                            display: 'flex',
                            flexDirection: "column",
                            alignItems: 'center',
                            justifyContent: 'center',
                            gap: "30px",
                            backgroundColor: "white",
                            padding: "100px",
                            borderRadius: "20px"
                        }}

                    >
                        <Box
                            component="img"
                            src={"/assets/images/the-throne-logo.svg"}
                            sx={{
                                width: "200px",
                            }}
                        />
                        <PrimaryButton

                            onClick={() => {
                                window.location.href = 'mailto:info@thronetools.com';
                            }}
                        >Send Email</PrimaryButton>
                    </Box>
                }

                {
                    mode === "phone" &&
                    <Box
                        sx={{
                            minWidth: "200px",
                            display: 'flex',
                            flexDirection: "column",
                            alignItems: 'center',
                            justifyContent: 'center',
                            gap: "30px",
                            backgroundColor: "white",
                            padding: "100px",
                            borderRadius: "20px"
                        }}

                    >
                        <Box
                            component="img"
                            src={"/assets/images/the-throne-logo.svg"}
                            sx={{
                                width: "200px",
                            }}
                        />
                        <PrimaryButton

                            onClick={() => {
                                window.open('tel:+15035724869');

                            }}
                        >Call US</PrimaryButton>
                    </Box>
                }

                {
                    mode === "linkedin" &&
                    <Box
                        sx={{
                            minWidth: "200px",
                            display: 'flex',
                            flexDirection: "column",
                            alignItems: 'center',
                            justifyContent: 'center',
                            gap: "30px",
                            backgroundColor: "white",
                            padding: "100px",
                            borderRadius: "20px"
                        }}

                    >
                        <Box
                            component="img"
                            src={"/assets/images/the-throne-logo.svg"}
                            sx={{
                                width: "200px",
                            }}
                        />
                        <PrimaryButton
                            onClick={() => {
                                window.open("https://www.linkedin.com/in/nick-safstrom-32b4ab18a", '_blank')
                            }}
                        >Visit Linkedin</PrimaryButton>
                    </Box>
                }


                {
                    mode === "info" &&
                    <Box
                        sx={{
                            minWidth: "200px",
                            display: 'flex',
                            flexDirection: "column",
                            alignItems: 'center',
                            justifyContent: 'center',
                            gap: "30px",
                            backgroundColor: "white",
                            padding: "100px",
                            borderRadius: "20px"
                        }}

                    >
                        <Box
                            component="img"
                            src={"/assets/images/the-throne-logo.svg"}
                            sx={{
                                width: "200px",
                            }}
                        />
                        <PrimaryButton

                        // onClick={props.setComingSoon}
                        >Register</PrimaryButton>
                    </Box>
                }

                {
                    mode === "video" &&
                    <Box
                        sx={{
                            minWidth: "200px",
                            display: 'flex',
                            flexDirection: "column",
                            alignItems: 'center',
                            justifyContent: 'center',
                            gap: "30px",
                        }}

                    >
                        <Box
                            component='video'
                            autoPlay
                            controls
                            muted
                            src="/assets/videos/the-throne.mp4"
                            sx={{
                                width: "100%",
                                maxWidth: "350px",
                            }}
                        >
                        </Box>
                    </Box>
                }

                {
                    mode === "register" &&
                    <Box
                        sx={{
                            minWidth: "200px",
                            display: 'flex',
                            flexDirection: "column",
                            alignItems: 'center',
                            justifyContent: 'center',
                            gap: "30px",
                            backgroundColor: "white",
                            padding: "100px",
                            borderRadius: "20px"
                        }}

                    >
                        <Box
                            component='img'
                            src={"/assets/images/the-throne-logo.svg"}
                            sx={{
                                width: "150px",
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}>
                        </Box>
                        <PrimaryButton
                            onClick={() => {
                                window.open('https://share.hsforms.com/13qAGJiFCQKufJZKye8CDagqugqc', '_blank')
                            }}
                        >Register</PrimaryButton>
                    </Box>
                }

                { 
                    mode === "distributor" &&
                    <Box
                        sx={{
                            minWidth: "200px",
                            display: 'flex',
                            flexDirection: "column",
                            alignItems: 'center',
                            justifyContent: 'center',
                            gap: "30px",
                            backgroundColor: "white",
                            padding: "100px",
                            borderRadius: "20px"
                        }}
                    >
                        <Box
                            component='img'
                            src={"/assets/images/the-throne-logo.svg"}
                            sx={{
                                width: "150px",
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}>
                        </Box>
                        <PrimaryButton
                            sx={{
                                width: "100%",
                                maxWidth: "350px",
                            }}
                            onClick={() => {
                                window.open('https://share.hsforms.com/17EH8wTuVRR29gVuCtxLwtAqugqc', '_blank')
                            }}
                        >Become a Distributor</PrimaryButton>
                    </Box>
                }

                { 
                    mode === "TISE_2025_GIVEAWAY" &&
                    <Box
                        sx={{
                            minWidth: "200px",
                            display: 'flex',
                            flexDirection: "column",
                            alignItems: 'center',
                            justifyContent: 'center',
                            gap: "30px",
                            backgroundColor: "white",
                            padding: "100px",
                            borderRadius: "20px"
                        }}
                    >
                        <Box
                            component='img'
                            src={"/assets/images/the-throne-logo.svg"}
                            sx={{
                                width: "150px",
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}>
                        </Box>
                        <PrimaryButton
                            sx={{
                                width: "100%",
                                maxWidth: "350px",
                            }}
                            onClick={() => {
                                window.open('https://share.hsforms.com/1a_cBp230RZq141fBnmvGvAqugqc', '_blank')
                            }}
                        >TISE 2025 GIVEAWAY</PrimaryButton>
                    </Box>
                }

                <CloseButton onClick={() => { setMode(null) }} />


            </Box>
        </Modal>
    );
}

export default SideModal;



